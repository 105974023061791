import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { DeviceReadingModalItem } from "../area-device-reading-item/DeviceReadingModalItem";
import Co2AreasIcon from "../areas-icons/Co2AreasIcon";
import EnviroQIcon from "../areas-icons/EnviroQIcon";
import HumidityAreaIcon from "../areas-icons/HumidityAreaIcon";
import TempAreasIcon from "../areas-icons/TempAreasIcon";
import { SampleBreakdown } from "./makeSampleBreakdown";
import LightIcon from "../areas-icons/LightIcon";
import PressureIcon from "../areas-icons/PressureIcon";
import SoundIcon from "../areas-icons/SoundIcon";
import SignalStrengthIndicator from "../../indicators/SignalStrengthIndicator";
import { BatteryLevel } from "./BatteryLevel";
import { GraphColor } from "../../../lib/api/types/Room";
import { OutsideWithReading } from "../../../lib/api/types/OutsideWithReading";
import { LocationType, QueryKeys, useUpdateLocationMutation } from "../../../lib/api/properties/hooks";
import { BsPencil } from "react-icons/bs";
import { RoomColorItem, RoomColorPicker } from "../../create-room/RoomColorPicker";
import { RoomLocationWithReading } from "../../../lib/api/types/RoomLocation";
import { format } from "date-fns";
import { useQueryClient } from "react-query";
import AirwitzPMIcon from "../areas-icons/AirwitzPMIcon";
import { getDeviceIcon } from "./getDeviceIcon";
import { formatDeviceName } from "../../../lib/utils/formatDeviceName";
import { PrimaryButton } from "../../button/PrimaryButton";
import { useRouter } from "next/router";
import numeral from "numeral";
import { isSampleRecent } from "../../../lib/utils/isSampleRecent";
import { GatewayLinkedDevices } from "./GatewayLinkedDevices";

interface RoomLocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomLocation: RoomLocationWithReading | OutsideWithReading;
  locationType: "ROOMLOCATION" | "OUTSIDE";
  propertyId: string;
  breakdown?: SampleBreakdown;
  editMode?: boolean;
  hasDevice: boolean;
}

const PADDING_HORIZONTAL = "24px";

export const RoomLocationModal = ({
  isOpen,
  roomLocation,
  locationType,
  onClose,
  breakdown,
  propertyId,
  editMode,
  hasDevice,
}: RoomLocationModalProps) => {
  const [shouldEditName, setShouldEditName] = useState(Boolean(editMode));
  const [shouldEditColor, setShouldEditColor] = useState(false);

  const [editedName, setEditedName] = useState(roomLocation.name);
  const [editedColor, setEditedColor] = useState(roomLocation.color);

  const router = useRouter();

  const [editedRoomLocationName, setEditedRoomLocationName] = useState(roomLocation.name);
  const queryClient = useQueryClient();

  const updateLocationmutation = useUpdateLocationMutation(
    locationType === "OUTSIDE" ? LocationType.Outside : LocationType.RoomLocation
  );

  const icons = [
    {
      hasHealthingReading: breakdown?.co2?.isHealthy,
      icon: <Co2AreasIcon width={28} height={28} />,
      title: "CO2",
      reading: breakdown?.co2?.label,
    },
    {
      hasHealthingReading: breakdown?.temp?.isHealthy,
      icon: <TempAreasIcon width={28} height={28} />,
      title: "Temp",
      reading: breakdown?.temp?.label,
    },
    {
      hasHealthingReading: breakdown?.occupied?.isHealthy,
      icon: <AirwitzPMIcon size={16} />,
      title: "Occupancy",
      reading: breakdown?.occupied?.label,
    },
    {
      hasHealthingReading: breakdown?.humidity?.isHealthy,
      icon: <HumidityAreaIcon width={28} height={28} />,
      title: "Humidity",
      reading: breakdown?.humidity?.label,
    },
    // {
    //   hasHealthingReading: breakdown?.co2?.isHealthy,
    //   icon: <DewPointIcon />,
    //   title: "Dew point",
    //   reading: breakdown?.co2?.label,
    // },
    {
      hasHealthingReading: breakdown?.sound?.isHealthy,
      icon: <SoundIcon width={28} height={28} />,
      title: "Sound",
      reading: breakdown?.sound?.label,
    },
    {
      hasHealthingReading: breakdown?.light?.isHealthy,
      icon: <LightIcon width={28} height={28} />,
      title: "Light",
      reading: breakdown?.light?.label,
      ignoreHealthReading: true,
    },
    {
      hasHealthingReading: breakdown?.pressure?.isHealthy,
      icon: <PressureIcon width={28} height={28} />,
      title: "Pressure",
      reading: breakdown?.pressure?.label,
      ignoreHealthReading: true,
    },
    {
      hasHealthingReading: undefined,
      icon: <AirwitzPMIcon size={28} />,
      title: "PM 1",
      reading: breakdown?.pm1?.label,
      ignoreHealthReading: true,
    },
    {
      hasHealthingReading: undefined,
      icon: <AirwitzPMIcon size={28} />,
      title: "PM 2.5",
      reading: breakdown?.pm2_5?.label,
      ignoreHealthReading: true,
    },
    {
      hasHealthingReading: undefined,
      icon: <AirwitzPMIcon size={28} />,
      title: "PM 10",
      reading: breakdown?.pm10?.label,
      ignoreHealthReading: true,
    },

    {
      hasHealthingReading: undefined,
      icon: <AirwitzPMIcon size={28} />,
      title: "Min TVOC",
      reading: breakdown?.minTVOC?.label,
      ignoreHealthReading: true,
    },
    {
      hasHealthingReading: undefined,
      icon: <AirwitzPMIcon size={28} />,
      title: "TVOC",
      reading: breakdown?.tvoc?.label,
      ignoreHealthReading: true,
    },
    {
      hasHealthingReading: undefined,
      icon: <AirwitzPMIcon size={28} />,
      title: "Max TVOC",
      reading: breakdown?.maxTVOC?.label,
      ignoreHealthReading: true,
    },
  ].filter((r) => r.reading);

  const handleSaveName = () => {
    updateLocationmutation.mutate({
      propertyId,
      input: {
        id: roomLocation.id,
        name: editedRoomLocationName,
      },
      onSuccess: () => {
        setShouldEditName(false);
        setEditedName(editedRoomLocationName);
        console.log("INvalidating cache");

        queryClient.invalidateQueries([QueryKeys.PROPERTY, propertyId]);
        queryClient.invalidateQueries([QueryKeys.PROPERTY_READINGS, propertyId]);
      },
    });
  };

  const handleSaveColor = (color: GraphColor) => {
    updateLocationmutation.mutate({
      propertyId,
      input: {
        id: roomLocation.id,
        color: color,
      },
      onSuccess: () => {
        setShouldEditColor(false);
        setEditedColor(color);
        console.log("INvalidating cache");

        queryClient.invalidateQueries([QueryKeys.PROPERTY, propertyId]);
        queryClient.invalidateQueries([QueryKeys.PROPERTY_READINGS, propertyId]);
      },
    });
  };

  const lastSeenAt = roomLocation.device?.lastSample?.timestamp;
  const serialNumber = roomLocation.device?.shortSerial;
  const sku = roomLocation.device?.sku;

  return (
    <Modal size="xl" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton mt={"2.5"} />
        <Flex mt={"12px"} ml={PADDING_HORIZONTAL} mr={"50px"} flexDir="row" justifyContent="space-between">
          <HStack>
            <RoomColorItem
              color={editedColor as GraphColor}
              isSelected={shouldEditColor}
              onClick={() => {
                setShouldEditColor(!shouldEditColor);
              }}
            />
            <Tooltip label={formatDeviceName(roomLocation.device?.type)}>
              <Flex>
                {getDeviceIcon(roomLocation.device?.type)}
              </Flex>
            </Tooltip>
            <HStack ml="4px">
              {Boolean(!shouldEditName) && (
                <Text
                  fontSize="16px"
                  color="gray.500"
                  fontWeight={"bold"}
                  overflow="hidden"
                  textOverflow={"ellipsis"}
                  maxWidth={300}
                >
                  {editedName}
                </Text>
              )}
              {Boolean(shouldEditName) && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSaveName();
                  }}
                >
                  <Input
                    autoFocus={true}
                    value={editedRoomLocationName}
                    onChange={(e) => setEditedRoomLocationName(e.target.value)}
                    w={240}
                  />
                </form>
              )}

              <>
                {Boolean(!shouldEditName) && (
                  <Button variant={"link"} onClick={() => setShouldEditName(true)}>
                    <Icon as={BsPencil} />
                  </Button>
                )}
                {Boolean(shouldEditName) && (
                  <Button variant={"link"} onClick={handleSaveName}>
                    Save
                  </Button>
                )}
              </>
            </HStack>
          </HStack>
          <Flex alignItems="center">
            {!breakdown?.isReallyOld && breakdown?.battery !== undefined && (
              <Box pl={2} pr={2} borderRadius={10} mt={1} mr="4px" display="flex" alignItems="center">
                <BatteryLevel battery={breakdown.battery} />
              </Box>
            )}

            {Boolean(
              !breakdown?.isReallyOld && typeof breakdown?.signalStrengthPercentage !== "undefined"
            ) && <SignalStrengthIndicator signalStrength={breakdown!.signalStrengthPercentage!} />}
          </Flex>
        </Flex>
        {shouldEditColor && (
          <Box px={5}>
            <RoomColorPicker
              onSelectColor={handleSaveColor}
              selectedColor={roomLocation.color as GraphColor}
            />
          </Box>
        )}

        <ModalBody display="flex" pt="8px" flexDirection="column" alignItems="center" px={PADDING_HORIZONTAL}>
          {Boolean(roomLocation.image) && (
            <Image
              borderRadius={8}
              onClick={() => window.open(roomLocation.image, "_blank")}
              cursor="pointer"
              alt={`${roomLocation.name} Image`}
              src={roomLocation.image}
              objectFit="cover"
              height="242px"
              width="100%"
              onError={(e) => {
                e.currentTarget.style.display = "none";
              }}
            />
          )}

          {Boolean(breakdown && !breakdown.isOnline) && (
            <Alert status="warning" mt={5}>
              <HStack>
                <AlertIcon />
                <Box>Device is offline</Box>
                {Boolean(lastSeenAt) && (
                  <Box> Last Seen: {format(new Date(lastSeenAt!), "d/M/Y h:mm a")}</Box>
                )}
              </HStack>
            </Alert>
          )}

          {Boolean(breakdown?.healthyBreakdownText && !breakdown.isReallyOld) && (
            <Text fontSize={16} mt={6} mb={10} textAlign="center">
              {breakdown?.healthyBreakdownText}
            </Text>
          )}

          <Box width="100%" mb="32px">
            {Boolean(breakdown?.isReallyOld === false) && (
              <SimpleGrid minChildWidth={"100px"} spacing={2}>
                {icons.map((i) => (
                  <DeviceReadingModalItem
                    key={i.title}
                    icon={i.icon}
                    reading={i.reading!}
                    title={i.title}
                    hasHealthyReading={Boolean(i.hasHealthingReading)}
                    ignoreHealthReading={i.ignoreHealthReading}
                  />
                ))}
              </SimpleGrid>
            )}

            <Box pt={2} fontSize={14}>
              <strong>Device: </strong>
              {formatDeviceName(roomLocation.device?.type)}
            </Box>
            <Box pt={2} fontSize={14}>
              <strong>Last seen:</strong>{" "}
              {lastSeenAt ? format(new Date(lastSeenAt!), "d/M/Y h:mm a") : "Never"}
              {isSampleRecent(roomLocation.device?.lastSample, roomLocation.device?.type) && (
                <Tag ml={3} key="sm" variant="solid" colorScheme="green">
                  Online
                </Tag>
              )}
            </Box>
            <Box pt={2} fontSize={14}>
              <strong>{breakdown?.isReallyOld ? "Last Known Battery: " : "Battery Level:"}</strong>{" "}
              {breakdown?.battery !== undefined ? numeral(breakdown!.battery).format("0%") : "N/A"}
            </Box>
            <Box pt={2} fontSize={14}>
              <strong>Serial:</strong> {serialNumber ? serialNumber : "N/A"}
            </Box>
            <Box pt={2} fontSize={14}>
              <strong>SKU:</strong> {sku ? sku : "N/A"}
            </Box>
          </Box>

          {Boolean(roomLocation.device?.type === "GATEWAY") && (
            <GatewayLinkedDevices device={roomLocation.device} installationId={propertyId} />
          )}

          {!hasDevice && (
            <Text p={5} pt={0} pb={8}>
              No device found for this area, use the mobile app to configure a device.
            </Text>
          )}
          <Box>
            {Boolean(
              window.location.pathname !== `/area/outside/${roomLocation.id}` &&
              locationType === LocationType.RoomLocation
            ) && (
                <PrimaryButton
                  onClick={() => router.push(`/area/room/${(roomLocation as RoomLocationWithReading).roomId}`)}
                  text="View Room"
                />
              )}
            {Boolean(
              window.location.pathname !== `/area/outside/${roomLocation.id}` &&
              locationType === LocationType.Outside
            ) && (
                <PrimaryButton
                  onClick={() => router.push(`/area/outside/${roomLocation.id}`)}
                  text="View Outside Area"
                />
              )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
